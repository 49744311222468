import classes from './style.module.scss';
import classNames from "classnames";
import { C_PhoneContacts } from '../c_PhoneContacts';
import { C_Address } from '../c_Address';

interface FooterContacts {
  className?: any,
  phone_number: string,
  mail: string,
  address: string,
  sales_number: string,
  develop: {
    text: string,
    mail: string
  }
}

export const C_FooterContacts = ({className, phone_number, mail, address, sales_number, develop}: FooterContacts) => {

  const cls = classNames(classes.root, {[className]: className});

    return (
      <div className={cls}>
        <C_PhoneContacts phone_number={phone_number} mail={mail} /> 
        <C_Address address={address} sales_number={sales_number} develop={develop}/>
      </div>
    )
}