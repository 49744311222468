import classNames from 'classnames';
import { useContext } from 'react';
import classes from './style.module.scss';
import {Context} from '../../../library';
import {sizes} from '../../../data/sizes';

export const C_Social = ({className} : {className?: any}) => {

  const cls = classNames(classes.root, {[className]: className});

  const {size, menu} = useContext(Context);
  const {width, height} = size;

  return (
    <div className={cls}>
      <span>Следуйте за нами</span>

      <a href="https://api.whatsapp.com/send?phone=79263677684" target="_blank" rel='noreferrer'>
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <path id="free-icon-whatsapp-1051323" d="M13.5,0A13.553,13.553,0,0,0,0,13.5a13.41,13.41,0,0,0,1.911,6.914L0,27l6.586-1.911A13.41,13.41,0,0,0,13.5,27a13.5,13.5,0,1,0,0-27Zm8.44,19.2-.628.628c-.889.889-2.932.818-4.246.565C12.7,19.553,7.61,14.619,6.628,10.346c-.486-2.117-.218-3.957.484-4.659l.628-.628A.889.889,0,0,1,9,5.059l2.513,2.513a.889.889,0,0,1,0,1.255l-.628.629a1.78,1.78,0,0,0,0,2.513l4.149,4.095a1.776,1.776,0,0,0,2.513,0l.628-.628a.928.928,0,0,1,1.257,0l2.512,2.512A.888.888,0,0,1,21.94,19.2Zm0,0" fill="#2b2c2c"/>
        </svg>
      </a>
      <a href="https://t.me/stone_by_stonehedge" target="_blank" rel='noreferrer'>
      {width < sizes.widthDesktopSm ?
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g id="icon_ТГ" data-name="icon ТГ" transform="translate(-179.606 -3860.101)">
            <circle id="Эллипс_3" data-name="Эллипс 3" cx="13.5" cy="13.5" r="13.5" transform="translate(179.606 3860.101)" fill="#2b2d2c"/>
            <path id="Контур_183" data-name="Контур 183" d="M1291.348,5464.77l8.967-3.694c.885-.386,3.887-1.617,3.887-1.617s1.385-.539,1.27.77c-.039.539-.346,2.424-.654,4.464l-.962,6.042s-.077.885-.732,1.039a3.158,3.158,0,0,1-1.924-.692c-.154-.115-2.886-1.848-3.887-2.695a.728.728,0,0,1,.039-1.23c1.386-1.271,3.04-2.848,4.041-3.849.462-.461.923-1.54-1-.231l-5.426,3.656a2.257,2.257,0,0,1-1.77.039c-1.154-.346-2.5-.808-2.5-.808s-.924-.578.654-1.193Z" transform="translate(-1105.897 -1591.403)" fill="#fff"/>
            </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="41.856" height="36.787" viewBox="0 0 41.856 36.787">
          <path id="free-icon-telegram-1051317" d="M10.071,21.315l5.206,13.014,6.777-6.777,11.62,9.236L41.856,0,0,17.434ZM29.9,10.555,17.084,22.24l-1.6,6.014-2.948-7.371Zm0,0" fill="#2b2c2c"/>
        </svg>
      }
      </a>
  </div>
  )
}
