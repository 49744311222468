import classes from './style.module.scss';
import classNames from "classnames";
import {C_Nav_Element} from "../c_Nav_Element/index";

export const C_Nav_List = ({className, data} : {className?: any; data?: any[]}):JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    
    return (
        <ul className={cls}>
            {data ? data.map((el, item) => {
                return <C_Nav_Element
                    el={el}
                    item={item}
                    key={el+item}
                />
            }): null}
        </ul>
    )
}