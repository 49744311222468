import classes from './style.module.scss';
import classNames from "classnames";
// import {useStore} from "../../store/stores";
import {useContext} from "react";
import {Context} from "../../../library";

export const C_Nav_Sub_Element = ({className, el, i, item_key} : {className?: any, el: {name: string, color: string,link: string, anchor: string, isNewPage: string}, i: number, item_key: number}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    const {name, color, link, anchor, isNewPage} = el;
    // const store = useStore();
    const {size, menu} = useContext(Context);
    const [menuState, setMenuState ] = menu;
    
    return (
        <li
            className={cls}
            key={item_key}
        >{link.length != 0 ?
            (<a
                href={`${link}`}
                target={isNewPage === "false" ? "_self" : "_blank"}
                rel="noreferrer"
                className={classes.projectName}
                onClick={()=> setMenuState(false)}>
                <span className={classes.marked}>STONE </span>
                <span>{name}</span>
                <span
                    className={classes.underline}
                    style={{backgroundColor: `${color}`}}
                />
            </a>) : (
                <a
                    href={`/`}
                    className={classes.projectName}
                    onClick={(e) => {
                        e.preventDefault();
                        setMenuState(false)
                        // store.switchPopUpFormState();
                    }}>
                    <span className={classes.marked}>STONE </span>
                    <span>{name}</span>
                    <span
                        className={classes.underline}
                        style={{backgroundColor: `${color}`}}
                    />
                </a>
            )}
        </li>
    )
}