import classes from './style.module.scss';
import classNames from "classnames";
import { C_Navigation } from "../s_Menu/c_Navigation/index";
import {C_ContactsMenu} from './c_ContactsMenu'
import {useRef} from "react";
import menuOnTop from "../../hooks/MenuOnTop";
import { navData } from '@/src/data/mocks';
import { C_LogoStone } from '@/src/components/c_LogoStone';

export const S_Menu = ({className}: {className?: any}): JSX.Element => {
    const topMenuEl = useRef(null);
    const cls = classNames(classes.root, {[classes.menuOnTop]: menuOnTop(topMenuEl), [className]: className});
    return (
        <div className={cls} ref={topMenuEl}>
            <C_LogoStone className={classes.C_Logo_Menu} />
            <C_Navigation className={classes.C_Navigation} data={navData}/>
            <C_ContactsMenu className={classes.C_ContactsMenu} data={navData} />
        </div>
    )
}