import classes from './style.module.scss';
import classNames from "classnames";
import {C_MainButton} from "@/src/components/c_MainButton";
import {C_Nav_List} from "../c_Nav_List";
import {useContext, useEffect, useRef} from "react";
import {Context} from "@/src/library";
import {C_LogoStone} from '@/src/components/c_LogoStone';

export function C_Navigation({className, data}: { className?: any, data: any[] }): JSX.Element {
    const cx = classNames(classes.root, {[className]: className});
    const burgerRef: any = useRef(null);
    const {menu, popup} = useContext(Context);
    const [menuState, setMenuState] = menu;
    const [popupState, setPopupState] = popup;

    useEffect(() => {

        window.addEventListener('click', function (evt) {
            if (burgerRef.current) {
                const target = evt.target as Element
                if (evt.target !== null && evt.target) {
                    if (burgerRef.current.classList.contains(classes.showElements) &&
                        !target.classList.contains(classes.burger) &&
                        !target.classList.contains(classes.elements) &&
                        target.textContent !== 'Проекты') {
                    }
                }
            }
        })

        const body = document.querySelector('body');
        if (menuState) {
            body !== null ? body.style.overflow = 'hidden' : null;
        } else {
            body !== null ? body.style.overflow = 'auto' : null;
        }
    }, [menuState])

    let x1: any = null;
    let y1: any = null;

    function handleTouchStart(evt: any) {
        x1 = evt.touches[0].clientX;
        y1 = evt.touches[0].clientY;
    }

    function handleTouchMove(evt: any) {
        if (!x1 || !y1) {
            return false;
        }

        let x2 = evt.touches[0].clientX;

        let xDiff = x2 - x1;

        if (xDiff < -30) {
            setMenuState(false)
        }
    }

    return (
        <nav className={cx}>
            <div onClick={() => {
                setMenuState((prev: any) => !prev)
            }}
                 className={classNames(classes.burger, {[classes.openBurger]: menuState})}
            />
            <div className={classNames(classes.menu, {[classes.background]: menuState})} onClick={
                () => {
                    setMenuState(false)
                }
            }>
                <div className={classNames(
                    classes.elements,
                    {[classes.showElements]: menuState})}
                     ref={burgerRef}
                     onClick={(e) => {
                         e.stopPropagation()
                         return false;
                     }}
                     onTouchStart={(e) => handleTouchStart(e)}
                     onTouchMove={(e) => handleTouchMove(e)}
                >
                    <C_LogoStone className={classes.C_Logo}/>
                    <C_Nav_List className={classes.C_Nav_List} data={data}/>
                    <C_MainButton text={"Оставить заявку"} onClick={() => setPopupState((o: any) => !o)}
                                  className={classes.C_MainButton} mode={true}/>
                </div>
            </div>
        </nav>
    )
}
