import Link from 'next/link';
import classes from './style.module.scss';
import classNames from "classnames";

export const C_LogoSH = ({className, link}: { className?: any, link?: string }):JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    return (
        <>
            {
                link ?
                    <Link legacyBehavior href={link} rel="noreferrer" target={"_self"}>
                        <a className={cls}/>
                    </Link>
                    :
                    <span className={cls}/>
            }
        </>
    )
}