import './../styles/globals.scss';
import type { AppProps } from 'next/app';
import {Context, UtmAdd} from "@/src/library";
import React, { useEffect, useState } from 'react';
import useWindowSize from "../src/hooks/useWindowSize";
import { S_Footer } from '@/src/sections/s_Footer';
import { S_Menu } from '@/src/sections/s_Menu';
import TagManager from 'react-gtm-module';
import { footerData } from '@/src/data/mocks';

export default function App({ Component, pageProps }: AppProps) {

  const size = useWindowSize();
  const [menuState, setMenuState] = useState<boolean>(false);
  const [popupState, setPopupState] = useState(false);
  const [leadState, setLeadState] = useState(false);
  UtmAdd();
  useEffect(() => {
    TagManager.initialize({gtmId: 'GTM-PGT9Q3R'});
}, []);

  return (
    <Context.Provider value={{size: size, menu:[menuState, setMenuState], popup: [popupState, setPopupState], lead:[leadState, setLeadState]}}>
      <S_Menu />
      <Component {...pageProps} />
      <S_Footer data={footerData}/>
    </Context.Provider>
  )
}
