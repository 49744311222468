import ParksJPG from '../sections/s_StoneStandarts/c_SliderElement/img/parkx2.jpg'
import SportsJPG from '../sections/s_StoneStandarts/c_SliderElement/img/sportx2.jpg'
import RestoransJPG from '../sections/s_StoneStandarts/c_SliderElement/img/restoransx2.jpg'
import GreenJPG from '../sections/s_StoneStandarts/c_SliderElement/img/greenx2.jpg'
import DesignJPG from '../sections/s_StoneStandarts/c_SliderElement/img/designx2.jpg'
import Tower1JPG from '../sections/S_ProfitableInvestment/c_InvestmentCard/img/tower1x2.jpg'
import Tower2JPG from '../sections/S_ProfitableInvestment/c_InvestmentCard/img/tower2x2.jpg'
import Tower3JPG from '../sections/S_ProfitableInvestment/c_InvestmentCard/img/tower3x2.jpg'
import tower1_plan1 from "../sections/s_Plans/img/tower1_plan1.png";
import tower1_plan2 from "../sections/s_Plans/img/tower1_plan2.png";
import tower1_plan3 from "../sections/s_Plans/img/tower1_plan3.png";
import tower1_plan4 from "../sections/s_Plans/img/tower1_plan4.png";
import tower1_plan5 from "../sections/s_Plans/img/tower1_plan5.png";
import tower1_plan6 from "../sections/s_Plans/img/tower1_plan6.png";
import tower1_plan7 from "../sections/s_Plans/img/tower1_plan7.png";
import tower2_plan1 from "../sections/s_Plans/img/tower2_plan1.png";
import tower2_plan2 from "../sections/s_Plans/img/tower2_plan2.png";
import tower2_plan3 from "../sections/s_Plans/img/tower2_plan3.png";
import section3_plan1 from "../sections/s_Plans/img/section3_plan1.png";
import situationTower1 from '../sections//s_Plans/c_Tower_d_plans/c_Situation_Plan/img/situationTower1.jpg';
import situationTower2 from '../sections/s_Plans/c_Tower_d_plans/c_Situation_Plan/img/situationTower2.jpg';
import situationTower3 from '../sections/s_Plans/c_Tower_d_plans/c_Situation_Plan/img/situationTower3.jpg';
import projectImage from '../sections/s_Hero_section/img/title2x.jpg';

export const allPlans = [
    {
        name: 'Башня 1',
        square: `Общая площадь Башни 1 и${'\u00A0'}Башни${'\u00A0'}2${'\u00A0'}—${'\u00A0'}93${'\u00A0'}000${'\u00A0'}м`,
        plans: [
            {
                plan: tower1_plan1,
                floors: [7],
            },
            {
                plan: tower1_plan2,
                floors: [9, 11, 12],
            },
            {
                plan: tower1_plan3,
                floors: [13],
            },
            {
                plan: tower1_plan4,
                floors: [14],
            },
            {
                plan: tower1_plan5,
                floors: [15,17,18,19],
            },
            {
                plan: tower1_plan6,
                floors: [20, 21],
            },
            {
                plan: tower1_plan7,
                floors: [23, 24],
            },
        ],
        situationPlan: situationTower1,
        implement: ['от 50 м']

    },
    {
        name: 'Башня 2',
        square: `Общая площадь Башни 1 и${'\u00A0'}Башни${'\u00A0'}2${'\u00A0'}—${'\u00A0'}93${'\u00A0'}000${'\u00A0'}м`,
        plans: [
            {
                plan: tower2_plan3,
                floors: [15, 16],
            },
            {
                plan: tower2_plan1,
                floors: [10, 13],

            },
            {
                plan: tower2_plan2,
                floors: [14],
            }
        ],
        situationPlan: situationTower2,
        implement: ['от 70 м', `1 800 м`]
    },
    {
        name: 'Секция 3',
        square: '',
        plans: [
            {
                plan: section3_plan1,
                floors: [2, 3],
            }
        ],
        situationPlan: situationTower3,
        implement: [`от 555 м`]
    }
];

export const towers = [
    {
        name: 'Башня 1',
        label: 'Офисы и ритейл',
        description: 'Бизнес-центр',
        src: Tower1JPG,
        properties: {
            station_1: 'Савеловская',
            station_2: 'Савеловская',
            color_1: '#B4B4B4',
            color_2: '#8AC7C8',
            time_1: '7 мин. пешком',
            time_2: '10 мин. пешком',
            square: 'От 50 м',
            ready: 'Готовность: 2024'
        }
    },
    {
        name: 'Башня 2',
        label: 'Офисы и ритейл',
        description: 'Бизнес-центр',
        src: Tower2JPG,
        properties: {
            station_1: 'Савеловская',
            station_2: 'Савеловская',
            color_1: '#B4B4B4',
            color_2: '#8AC7C8',
            time_1: '7 мин. пешком',
            time_2: '10 мин. пешком',
            square: 'От 70 м',
            ready: 'Готовность: 2024'
        }
    },
    {
        name: 'Секция 3',
        label: 'Клиентские офисы',
        description: 'Трехэтажный камерный корпус',
        src: Tower3JPG,
        properties: {
            station_1: 'Савеловская',
            station_2: 'Савеловская',
            color_1: '#B4B4B4',
            color_2: '#8AC7C8',
            time_1: '7 мин. пешком',
            time_2: '10 мин. пешком',
            square: 'От 555 м',
            ready: 'Готовность: 2024'
        }
    }
]

export const heroData =
    {
        image: projectImage,
        sale: `Продажа офисов и ритейла от застройщика`,
        header: 'Бизнес-центр Класс А',
        description: ['Рассрочка 0%','Первый взнос 10%','Офис от 18 млн руб.','Ритейл от 16,1 млн руб.']
    }

export const footerData = {
    contacts: {
        phone: '+7 (495) 023-10-65',
        mail: 'sales_sh@stonehedge.ru',
        address: 'г. Москва, Бумажный проезд, вл. 19',
        sales: `Отдел продаж по телефону: пн.${'\u00A0'}- пт.: с${'\u00A0'}9:00 до${'\u00A0'}21:00 сб.${'\u00A0'}-${'\u00A0'}вс.:${'\u00A0'}c${'\u00A0'}9:30 до${'\u00A0'}20:00`,
        develop: {
            text: `Уважаемые покупатели, заключившие договоры с\u00A0ООО\u00A0«СТОУН\u00A0КОНЦЕПТ»! Информируем Вас о том, что\u00A0для\u00A0официальной переписки с застройщиком Вы можете использовать адрес электронной почты Застройщика: `,
            mail: 'info@stonesav.ru'
        }
    },
    copyright: {
        header: `Инвестируйте Выгодно В${'\u00A0'}ликвидную недвижимость`,
        name: 'Политика конфиденциальности',
        author: '© АО «СТОУНХЕДЖ»',
        description: `Содержимое данного сайта (включая размещенную информацию и материалы) охраняется авторским${'\u00A0'}правом (ст. 1271 ГК РФ). Запрещено копирование дизайна настоящего сайта, его структуры и отдельных элементов без предварительного письменного согласия АО «СТОУНХЕДЖ»`
    },
}

export const navData = [
    {
        "name": "Проекты",
        "anchor": '#',
        'link' : 'https://bc-stone.ru/#offers',
        "sub": [
            {
                "link": "https://bc-stone.ru/stone_tower_d",
                "name": "Towers | Tower D",
                "color": "#283380",
                "isNewPage": "true"
            },
            {
                "link": "https://bc-stone.ru/stone_svl",
                "name": "Савеловская",
                "color": "#D5FD01",
                "isNewPage": "true"
            },
            {
                "link": "https://stone-len.ru/",
                "name": "Ленинский",
                "color": "#43111D",
                "isNewPage": "true"
            },
            {
                "link": "https://bc-stone.ru/stone_dmr",
                "name": "Дмитровская",
                "color": "#7D5FA3",
                "isNewPage": "true"
            },
            {
                "link": "https://stone-tws.ru/",
                "name": "Towers",
                "color": "#283380",
                "isNewPage": "true"
            },
            {
                "link": "https://bc-stone.ru/kurskaya/",
                "name": "Курская",
                "color": "#5D6A63",
                "isNewPage": "false"
            },
        ]
    },
    {
        "name": "Девелопер",
        "link": 'https://bc-stone.ru/#developer',
        "sub": []
    },
    {
        "name": "Пресс-центр",
        "link": 'https://bc-stone.ru/#press-center',
        "sub": []
    },
    {
        "name": "Контакты",
        "anchor": "contacts",
        "sub": []
    }
];

export const sliderData = [
    {
        image: GreenJPG,
        color: 'black',
        description: 'Основное пространство занимает территория, предусмотренная для отдыха и прогулок. Она станет местом притяжения резидентов офисного центра.',
        title: 'Озеленение'
    },
    {
        image: DesignJPG,
        color: 'yellow',
        description: 'Вся территория стилобата будет благоустроена и предназначена для работы на свежем воздухе.',
        title: 'Ландшафтный дизайн'
    },
    {
        image: RestoransJPG,
        color: 'pink',
        description: 'Кафе и рестораны с летними верандами станут неотъемлемой частью офисной жизни сотрудников.',
        title: `Рестораны и${'\u00A0'}кафе`
    },
    {
        image: ParksJPG,
        color: 'green',
        description: 'В проекте предусмотрены развитые общественные пространства для индивидуальной работы и для коллективного мозгового штурма.',
        title: 'Парки и зоны отдыха'
    },
    {
        image: SportsJPG,
        color: 'blue',
        description: 'Часть территории займут workout зона, столы для пинг-понга. Здесь можно будет максимально быстро переключиться и восстановить эффективность.',
        title: 'Спортивная площадка'
    }
]
