import classNames from 'classnames';
import classes from './style.module.scss';
import Link from 'next/link';

interface Copyright {
  name: string,
  author: string,
  description: string
}

export const C_Copyright = ({className, copyright} : {className?: any, copyright: Copyright}):JSX.Element => {
  
  const cls = classNames(classes.root, {[className]: className});

  return (
      <div className={cls}>
        <div>
          <Link legacyBehavior href={'/policy '} rel="noreferrer">
            <a>{copyright.name}</a>
          </Link>
          </div>
        <div>{copyright.author}</div>
        <div>{copyright.description}</div>
      </div>
  )
}