import classes from './style.module.scss';
import classNames from "classnames";

export const C_ContactsMenu = ({className, data} : {className?: any, data?: any[]}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    return (
        <div className={cls}>
            <a href={"#"} className={classes.callMe} />
            <a href={"https://api.whatsapp.com/send?phone=79263677684"} target={"_blank"} rel="noreferrer" className={classes.whatsapp} />
            <a href={'tel:+74950231065'} className={classes.phone}>+7 (495) 023-10-65</a>
        </div>
    )
}
