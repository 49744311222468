import classNames from 'classnames';
import classes from './style.module.scss';

interface Address {
  className?: any,
  address: string,
  sales_number: string,
  develop: {
    text: string,
    mail: string
  }
}

export const C_Address = ({className, address, sales_number, develop}: Address): JSX.Element => {
    
  const cls = classNames(classes.root, {[className]: className});
 
  return (
      <div className={cls}>
          <p>{address}</p>
          <p>{sales_number}</p>
          <p>{develop.text}
            <a href={`mailto:${develop.mail}`}>{develop.mail}</a>
          </p>
      </div>
  )
}