import classNames from 'classnames';
import classes from './style.module.scss';
import Link from 'next/link';

export const C_FooterNavigation = ({className} : {className?: any}):JSX.Element => {
  
  const cls = classNames(classes.root, {[className]: className});

  return (
      <div className={cls}>
        <ul>
            <li>
              <Link legacyBehavior target={"_self"} href={"https://bc-stone.ru"}>
                <a>На главную
                  <span className={classes.underline}></span>
                </a>
              </Link>
            </li>
            <li>
                <a href={'#offices'}>Офисы
                  <span className={classes.underline}></span>
                </a>
            </li>
            <li>
                <a href={'#diary'}>Дневник строительства</a>
                <span className={classes.underline}></span>
            </li>
        </ul>
      </div>
  )
}