import classes from './style.module.scss';
import classNames from "classnames";
import { C_FooterContacts } from '@/src/sections/s_Footer/с_FooterContacts';
import { C_Copyright } from './c_Copyright';
import { C_Logo } from '../../components/c_Logo';
import { C_LogoSH } from '../../components/c_LogoSH';
import { C_FooterNavigation } from '@/src/sections/s_Footer/c_FooterNavigation';
import { C_Social } from '@/src/sections/s_Footer/c_Social';
import { C_OnTopBtn} from '../../components/c_OnTopBtn/index.js'
import { C_CallUsBtn } from '../c_CallUsBtn';

interface FooterData {
    contacts: {phone: string, mail: string, address: string, sales: string, develop: {text: string, mail: string}},
    copyright: {name: string, author: string, description: string},
}

export const S_Footer = ({className, data} : {className?: any, data: FooterData}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    return (
      <div className={classes.wrapRoot} id={'contacts'}>
          <div className={cls}>
                <div className={classes.footer_wrapper}>
                    <h2>ОФИС НЕ{`\u00A0`}СКОВЫВАЕТ, А{`\u00A0`}ВДОХНОВЛЯЕТ</h2>
                        <C_FooterContacts phone_number={data.contacts.phone} mail={data.contacts.mail} address={data.contacts.address} sales_number={data.contacts.sales} develop={data.contacts.develop}/>
                        <C_FooterNavigation />
                        <C_Social />
                        <div className={classes.copyright_wrapper}>
                            <div className={classes.logo_wrapper}>
                                <C_Logo className={classes.logo_footer}/>
                                <C_LogoSH className={classes.logoSH_footer}/>
                            </div>
                            <C_Copyright copyright={data.copyright}/>
                        </div>
                </div>
                <C_OnTopBtn className={classes.footer_ontop_btn}/>
                <C_CallUsBtn />
          </div>
      </div>
  )
}